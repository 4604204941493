<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col>
          <v-toolbar class="align-items-center" color="transparent" flat>
            <v-card-title class="pl-0">
              {{ $t("admin.partcategory.list.title") }}
            </v-card-title>

            <v-spacer></v-spacer>

            <v-btn dark color="primary" to="create" disabled append text>
              Ny vare kategori
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row align="start">
        <v-col>
          <v-data-table
          :headers="headers"
          :items="categories"
          :hide-default-footer="true"
          :items-per-page="-1"
          show-group-by
          group-by="group_Title"
          sort-by="title"
          class="elevation-1"
          :search="search"
        >
          <template #[`item.listPriceFactor`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.listPriceFactor"
              persistent
              large
              save-text="Gem"
              cancel-text="Annuller"
              @save="save(item)"
            >
              {{ item.listPriceFactor }}%

              <template #input>
                <v-text-field
                  v-model="item.listPriceFactor"
                  label="Ny værdi"
                  autofocus
                />
              </template>
            </v-edit-dialog>
          </template>

          <template #[`item.profitFactor`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.profitFactor"
              persistent
              large
              save-text="Gem"
              cancel-text="Annuller"
              @save="save(item)"
            >
              {{ item.profitFactor }}%

              <template #input>
                <v-text-field
                  v-model="item.profitFactor"
                  label="Ny værdi"
                  autofocus
                />
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

var searchTimeout = null;

export default {
  name: "PartCategoryList",
  data() {
    return {
      categories: [],
      loading: false,
      search: '',
      headers: [
        {
          text: 'Gruppe',
          align: 'start',
          sortable: true,
          groupable: true,
          value: 'group_Title',
        },
        {
          text: 'TPA-kategorier',
          align: 'start',
          sortable: false,
          groupable: false,
          value: 'title',
        },
        { 
          text: 'Listepris faktor',
          value: 'listPriceFactor',
          groupable: false
        },
        { 
          text: 'Profitfaktor', 
          value: 'profitFactor',
          groupable: false
        }
      ],
    };
  },

  mounted() {
    this.loading = true;

    this.$AuthService.api.get("adminpart/categories/tpa")
      .then((response) => {
        this.categories = response.data.map(cat => {
          return {
            ...cat,
            listPriceFactor: cat.listPriceFactor * 100,
            profitFactor: cat.profitFactor * 100
          }
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    async save(category) {
      // Apply transform before sending update request
      const data = {
        ...category,
        listPriceFactor: category.listPriceFactor / 100,
        profitFactor: category.profitFactor / 100
      };

      try {
        await this.$AuthService.api.patch(`adminpart/categories/tpa/${category.id}`, data);

        this.$notifier.showSuccess({ message: 'Ændringen blev gemt' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Ændringen blev ikke gemt!' });
      }
    }
  }
};
</script>

<style>
.part-table.v-data-table tr {
  cursor: pointer;
}
</style>
